import MUIAvatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import "./Avatar.css";

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

const sizes = {
  sm: { width: 56, height: 56 },
  md: { width: 72, height: 72 },
  lg: { width: 96, height: 96 },
  xl: { width: 128, height: 128 },
};

const Avatar = ({
  name = "",
  noTitle,
  imgSrc,
  altName,
  size = "md",
  className,
  textStyle = {},
}) => {
  const [first = [], last = []] = name?.split(" ");

  return (
    <Stack
      className={className}
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <MUIAvatar
        alt={name}
        src={imgSrc}
        sx={{
          ...sizes[size],
          bgcolor: stringToColor(name),
          fontSize: sizes[size].width / 3,
        }}
      >
        {`${first[0]}${last[0]}`}
      </MUIAvatar>
      {name && !noTitle && (
        <h2 className="avatar-name" style={textStyle}>
          {name}
        </h2>
      )}
    </Stack>
  );
};

export default Avatar;
