import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServerCard from "../../components/cards/ServerCard/ServerCard";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import HeroSection from "../../components/Hero/HeroSection";

import {
  getTopServers,
  selectTopServers,
} from "../../features/leaderboard.js/leaderboardSlice";

import "./Leaderboard.css";

const ServerLeaderboard = () => {
  const { data: topServers, loading, error } = useSelector(selectTopServers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTopServers());
  }, [dispatch]);

  return (
    <div>
      <Header />
      <HeroSection />
      <div className="leaderboard-container leader-grid">
        {!loading &&
          !error &&
          topServers.map((leader, index) => (
            <div className="leader-card-container">
              <div className="server-rank">{index + 1}</div>
              <ServerCard key={leader.name + index} server={leader} />
            </div>
          ))}
      </div>
      <Footer />
    </div>
  );
};

export default ServerLeaderboard;
