import Profile from "../../../assets/images/Profile.png";
import { tagUrlConverter } from "../../common/TagUtils";
import Card, { CardContent, CardHeader } from "../Card/Card";

import "./ServerCard.css";

const ServerCardTags = ({ tags = [] }) => (
  <div className="card-tags">
    {tags.map((tag, key) => (
      <span key={key}>
        <a href={`/tags/${tagUrlConverter(tag.name.toLowerCase())}`}>
          {tag.name}
        </a>
      </span>
    ))}
  </div>
);

const ServerCardStats = ({ onlineMemberCount, totalMemberCount, url }) => (
  <div className="card-stats">
    <div className="number-of-users">
      <span className="online-indicator"></span>
      <span>{onlineMemberCount}</span>
    </div>
    <div className="diamond-size">
      <img src={Profile} alt="users" />
      <span>{totalMemberCount}</span>
    </div>
    <div className="more-stats">
      <a
        href={`/server/${url}`}
        target="_blank"
        className="btn-vote-now"
        rel="noreferrer"
      >
        Vote Now
      </a>
    </div>
  </div>
);

const ServerCardAction = ({ url, discordUrl }) => (
  <div className="card-action">
    <span className="btn-card-action join">
      <a href={discordUrl} target="_blank" rel="noreferrer">
        Join Discord
      </a>
    </span>
    <a
      href={`/server/${url}`}
      target="_blank"
      className="btn-card-action server-list"
      rel="noreferrer"
    >
      Server Details
    </a>
  </div>
);

function ServerCard({ server, handleTagsClick }) {
  const {
    name,
    url,
    discordUrl,
    serverImageUrl,
    featuredTags,
    tags,
    onlineMemberCount,
    totalMemberCount,
  } = server;

  return (
    <Card className="server-card">
      <CardHeader
        img={{
          url:
            serverImageUrl ||
            "https://cdn.discordapp.com/icons/977353635187019776/430bc9f2c448e55dc01098cae01216ac",
          name: name + " server image",
        }}
        title={name}
        tags={featuredTags}
      />
      <CardContent>
        <p>
          {server.serverPageDescription.length > 750
            ? server.serverPageDescription.slice(0, 700) + "..."
            : server.serverPageDescription}
        </p>
      </CardContent>
      <ServerCardTags tags={tags} />
      <ServerCardStats
        onlineMemberCount={onlineMemberCount}
        totalMemberCount={totalMemberCount}
        url={url}
      />
      <ServerCardAction url={url} discordUrl={discordUrl} />
    </Card>
  );
}

export default ServerCard;
