import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import {
  getAvailableServers,
  createMyServer,
} from "../../features/myServers/myServerSlice";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import CreateServerForm from "../../components/AddServer/CreateServer/CreateServerForm";
import CreateServerPreview from "../../components/AddServer/PreviewServer/CreateServerPreview";

import "./AddServer.css";
import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";

function AddServer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState([]);

  const { isLoading, isError, message, isSavedSuccess, myAvailableServers } =
    useSelector((state) => state.myServer);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      discordId: "",
      name: "",
      tags: [],
      serverPageDescription: "",
      nsfw: "",
      websiteUrl: "",
    },
    validationSchema: Yup.object({
      discordId: Yup.string().required("This field is required"),
      serverPageDescription: Yup.string().required(
        "Please provide a description"
      ),
      tags: Yup.array()
        .min(1, "Please provide atleast 1 tag")
        .required("Please provide atleast 1 tag"),
    }),
    onSubmit: (values) => {
      const newServerValues = JSON.stringify(values, null, 2);
      console.log(newServerValues);
      dispatch(createMyServer(newServerValues));
    },
  });

  const populateTags = (tags) => {
    const res = [];
    tags.map((tag) => res.push({ name: tag }));

    formik.setFieldValue("tags", res, false);
  };

  const setServerName = (discordId) => {
    const serverName = myAvailableServers.find(
      (server) => server.id === discordId
    ).name;

    formik.setFieldValue("name", serverName, false);
  };

  useEffect(() => {
    if (isError) {
      console.log(message);
      return;
    }

    dispatch(getAvailableServers());
  }, [isError, message, dispatch]);

  useEffect(() => {
    populateTags(values);
  }, [values]);

  useEffect(() => {
    if (formik.values.discordId) {
      setServerName(formik.values.discordId);
    }
  }, [formik.values.discordId]);

  useEffect(() => {
    if (isSavedSuccess) {
      navigate("/account/myservers");
    }
  }, [isSavedSuccess, navigate]);

  return (
    <div className="add-server">
      <Header />
      <div className="create-server-container">
        <div>
          <h1>Create Server</h1>
        </div>
        {isLoading ? (
          <Box
            sx={{
              marginY: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className="create-server-section">
            <CreateServerForm
              formik={formik}
              myAvailableServers={myAvailableServers}
              values={values}
              setValues={setValues}
              status="add"
            />

            <CreateServerPreview
              formik={formik}
              myAvailableServers={myAvailableServers}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default AddServer;
