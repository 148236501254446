import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home/Home";
import LoginRedirect from "./pages/Login/LoginRedirect";
import Dashboard from "./pages/Dashboard/Dashboard";
import TagPage from "./pages/TagPage/TagPage";
import AddServer from "./pages/AddServer/AddServer";
import EditServer from "./pages/EditServer/EditServer";
import ViewServer from "./pages/ViewServer/ViewServer";
import Commands from "./pages/Commands/Commands";
import ReactGA from "react-ga4";
import ServerLeaderboard from "./pages/Leaderboard/ServerLeaderboard";
import UserLeaderboard from "./pages/Leaderboard/UserLeaderboard";

const activateTracking =
  window.location.hostname.includes("dev") ||
  window.location.hostname.includes("localhost")
    ? false
    : true;

if (activateTracking) {
  ReactGA.initialize("G-6S14K5LHB2");
}

const ProtectedRoute = ({ loginToken, redirectPath = "/" }) => {
  if (!loginToken) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

function App() {
  const { loginToken } = useSelector((state) => state.auth);
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/leaderboard/servers" element={<ServerLeaderboard />} />
          <Route path="/leaderboard/users" element={<UserLeaderboard />} />
          <Route path="/tags/:searchTerm" element={<TagPage />} />
          <Route path="/search/:searchTerm" element={<TagPage />} />
          <Route path="/server/:serverUrl" element={<ViewServer />} />
          <Route path="/loginredirect" element={<LoginRedirect />} />
          <Route path="/commands" element={<Commands />} />
          <Route element={<ProtectedRoute loginToken={loginToken} />}>
            <Route path="/account/myservers" element={<Dashboard />} />
            <Route path="/account/add-server" element={<AddServer />} />
            <Route
              path="/account/edit-server/:serverKey"
              element={<EditServer />}
            />
          </Route>
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
      <ToastContainer position="top-center" autoClose={5000} />
    </div>
  );
}

export default App;
