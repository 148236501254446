import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import {
  getServers,
  setCurrentPage,
  setSearchTag,
} from "../../../features/servers/serverSlice";

import Pagination from "../../pagination/Pagination";

import "./CardContainer.css";
import ServerCard from "../ServerCard/ServerCard";

function CardContainer({ page }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    servers,
    currentPage,
    pageSize,
    totalItems,
    totalPages,
    searchTag,
    sortFilter,
    isError,
    isLoading,
    message,
  } = useSelector((state) => state.servers);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [servers, currentPage]);

  useEffect(() => {
    if (isError) {
      console.log(message);
    }
  }, [isError, message]);

  useEffect(() => {
    console.log("filter", sortFilter);
    if (page && page === "tagPage") {
      dispatch(getServers(page));
    } else {
      dispatch(getServers());
    }
  }, [currentPage, searchTag, sortFilter, page, dispatch]);

  const handleTagsClick = (tag) => {
    dispatch(setSearchTag(tag));
    navigate(`/tags/${tag.toLowerCase()}`);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          marginTop: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="card-container">
      <div className="card-items">
        {servers?.length ? (
          servers.map((server) => {
            return (
              <ServerCard
                key={server.serverKey}
                server={server}
                handleTagsClick={handleTagsClick}
              />
            );
          })
        ) : (
          <p className="no-cards">There are no cards to display</p>
        )}
      </div>

      <div className="pagination">
        <Pagination
          totalCount={totalItems}
          currentPage={currentPage}
          pageSize={pageSize}
          totalPages={totalPages}
          onPageChange={(page) => dispatch(setCurrentPage(page))}
        />
      </div>
    </div>
  );
}

export default CardContainer;
