import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { setSearchTag } from "../../features/servers/serverSlice";

import "font-awesome/css/font-awesome.min.css";

import "./SearchBar.css";

library.add(faSearch);

function SearchBar({ tagsPage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInput = (e) => {
    if (e?.target?.value) {
      setSearchTerm(e.target.value);
    } else {
      setSearchTerm("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (searchTerm) {
        dispatch(setSearchTag(searchTerm));
        navigate(`/search/${searchTerm.toLowerCase()}`);
      }
    }
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    if (searchTerm) {
      dispatch(setSearchTag(searchTerm));
      navigate(`/search/${searchTerm.toLowerCase()}`);
    }
  };

  return (
    <React.Fragment>
      <div className={tagsPage ? "search-container tags" : "search-container"}>
        <SearchIcon className="search-icon" />

        <input
          type="text"
          placeholder="Search for the top Servers & Bots in Discord..."
          className="search-input"
          onChange={handleSearchInput}
          onKeyDown={handleKeyDown}
        />

        <button
          className="btn-search btn-search-desktop"
          onClick={handleOnClick}
        >
          Search
        </button>
      </div>

      <button className="btn-search btn-search-mobile" onClick={handleOnClick}>
        Search
      </button>
    </React.Fragment>
  );
}

export default SearchBar;
