import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import leaderboardService from "./leaderboardService";

const initialState = {
  topServers: {
    data: null,
    loading: true,
    error: null,
  },
  topUsers: {
    data: null,
    loading: true,
    error: null,
  },
};

export const getTopServers = createAsyncThunk("leaderboard/servers", async () =>
  leaderboardService.getTopServers().then((res) => res.data)
);
export const selectTopServers = (state) => state.leaderboard.topServers;

export const getTopUsers = createAsyncThunk("leaderboard/users", async () =>
  leaderboardService.getTopUsers().then((res) => res.data)
);
export const selectTopUsers = (state) => state.leaderboard.topUsers;

export const leaderboardSlice = createSlice({
  name: "leaderboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTopServers.pending, (state) => {
        state.topServers.loading = true;
        state.topServers.error = null;
      })
      .addCase(getTopServers.fulfilled, (state, action) => {
        state.topServers.loading = false;
        state.topServers.data = action.payload;
      })
      .addCase(getTopServers.rejected, (state, action) => {
        state.topServers.loading = false;
        state.topServers.error = action.error;
      })
      .addCase(getTopUsers.pending, (state) => {
        state.topUsers.loading = true;
        state.topUsers.error = null;
      })
      .addCase(getTopUsers.fulfilled, (state, action) => {
        state.topUsers.loading = false;
        state.topUsers.data = action.payload;
      })
      .addCase(getTopUsers.rejected, (state, action) => {
        state.topUsers.loading = false;
        state.topUsers.error = action.error;
      });
  },
});

export default leaderboardSlice.reducer;
