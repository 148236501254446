import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../components/Avatar/Avatar";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import HeroSection from "../../components/Hero/HeroSection";
import Pill from "../../components/Pill/Pill";
import {
  getTopUsers,
  selectTopUsers,
} from "../../features/leaderboard.js/leaderboardSlice";

const UserLeaderboard = () => {
  const { data: topUsers = [], loading, error } = useSelector(selectTopUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTopUsers());
  }, [dispatch]);

  console.log(topUsers);

  return (
    <div>
      <Header />
      <HeroSection />
      <div className="leaderboard-container">
        {!loading &&
          !error &&
          topUsers.map(({ username, points, avatarImageUrl }, index) => (
            <div className="leader-container">
              <h2 className="leader-rank">{index + 1}</h2>
              <Pill className="leader-pill">
                <Avatar
                  className="leader-pill-avatar"
                  name={username}
                  imgSrc={avatarImageUrl}
                  size="lg"
                  noTitle
                />
                <h2 className="leader-pill-name">{username}</h2>
                <h2 className="leader-pill-points">{points}</h2>
              </Pill>
            </div>
          ))}
      </div>
      <Footer />
    </div>
  );
};

export default UserLeaderboard;
