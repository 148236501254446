import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import "./Commands.css";

// components
import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";

function Commands() {
  const dispatch = useDispatch();

  return (
    <Box className="commands-container">
      <Header />
      <div className="commands-pane">
        <div className="commands-header">
          <h1>List of commands we offer</h1>
        </div>
        <div className="commands-name">
          <h2>Command name: /Bump</h2>
        </div>
        <div className="commands-description">
          <p>Bumps your server to the top of the list</p>
        </div>
        <div className="commands-name">
          <h2>Command name: /Invite</h2>
        </div>
        <div className="commands-description">
          <p>Update the Discord invite URL on your ReVote listing</p>
        </div>
        <div className="commands-name">
          <h2>Command name: /Sync</h2>
        </div>
        <div className="commands-description">
          <p>Link the ReVote bot to your discord listing.</p>
        </div>
        <div className="commands-name">
          <h2>Command name: /Lock</h2>
        </div>
        <div className="commands-description">
          <p>Lock the ReVote bot to current channel the command is run in</p>
        </div>
      </div>
      <Footer />
    </Box>
  );
}

export default Commands;
