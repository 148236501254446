import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const jwtTokenName =
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("dev")
    ? "discord-auth-jwt-dev"
    : "discord-auth-jwt";

const loginToken = Cookies.get(jwtTokenName);

// Get profileImage from localStorage
const profileImage = JSON.parse(localStorage.getItem("profileImage"));

const initialState = {
  code: null,
  discordState: null,
  loginToken: loginToken ? loginToken : null,
  profileImage: profileImage ? profileImage : null,
  loginState: null,
  redirectLocation: "",
  isError: false,
  isSuccess: false,
  loginSuccess: false,
  isLoading: false,
  message: "",
};

// Login User
export const loginUser = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    try {
      return await authService.loginUser(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logoutUser = createAsyncThunk("auth/logout", async () => {
  await authService.logoutUser(jwtTokenName);
});

// Get User Profile Image
export const getUserProfileImage = createAsyncThunk(
  "auth/getProfileImage",
  async (_, thunkAPI) => {
    try {
      return await authService.getProfileImage();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Login User
export const initVoteLogin = createAsyncThunk(
  "auth/initVoteLogin",
  async (payload, thunkAPI) => {
    try {
      return await authService.initVoteLogin(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setDiscordResponseCode(state, action) {
      state.code = action.payload;
    },
    setDiscordResponseState(state, action) {
      state.discordState = action.payload;
    },
    setAuthCookie(state) {
      state.loginToken = Cookies.get(jwtTokenName);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.redirectLocation = action.payload.data.redirectLocation;
        state.isLoading = false;
        state.isSuccess = true;
        state.loginSuccess = action?.payload?.data?.redirectLocation
          ? false
          : true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.loginSuccess = false;
        state.message = action.payload;
        toast.error(`Error logging in. Please try again later.`, {
          position: "bottom-left",
        });
      })

      .addCase(getUserProfileImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserProfileImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profileImage = action.payload.avatarImageUrl;
      })
      .addCase(getUserProfileImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(logoutUser.fulfilled, (state) => {
        state.loginToken = null;
      })

      .addCase(initVoteLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initVoteLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.loginState = action.payload.data.state;
      })
      .addCase(initVoteLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  setDiscordResponseCode,
  setDiscordResponseState,
  setAuthCookie,
} = authSlice.actions;
export default authSlice.reducer;
