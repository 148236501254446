import React, { useEffect, useState } from "react";

import { Chip, FormControl, Input, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  formControlRoot: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    maxWidth: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    border: "1px solid #2D405940",
    padding: "10px 15px",
    borderRadius: "12px",
    "&> div.container": {
      gap: "6px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    "& > div.container > span": {
      backgroundColor: "gray",
      padding: "2px 3px",
      borderRadius: "4px",
    },
  },
}));

function MultipleInput({ values, setValues, formik }) {
  const classes = useStyles();
  // const [values, setValues] = useState([]);
  const [currValue, setCurrValue] = useState("");
  const [disabledInput, setDisabledInput] = useState(false);

  const handleKeyUp = (e) => {
    // console.log(e.keyCode);
    if (e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      if (e.target.value.length >= 1) {
        setValues((oldState) => [...oldState, e.target.value.replace("#", "")]);
        setCurrValue("");
      }
    }
  };

  useEffect(() => {
    // console.log(values);
    if (values?.length >= 5) {
      setDisabledInput(true);
    } else {
      setDisabledInput(false);
    }

    // populateTags(values);
  }, [values]);

  const handleChange = (e) => {
    if (e?.target?.value?.length > 0 && e.target.value.includes("#")) {
      if (
        e.target.value.length >= 1 &&
        !values.includes(e.target.value.replace("#", ""))
      ) {
        setValues((oldState) => [...oldState, e.target.value.replace("#", "")]);
        setCurrValue("");
      }
    } else {
      setCurrValue(e.target.value);
    }
  };

  const handleDelete = (item, index) => {
    let arr = [...values];
    arr.splice(index, 1);
    // console.log(item)
    setValues(arr);
  };
  return (
    <div className="tags-form-control">
      <label htmlFor="tags">Tags (Required)</label>
      <FormControl classes={{ root: classes.formControlRoot }}>
        <div className={"container"}>
          {values?.map((item, index) => (
            <Chip
              key={index}
              size="sm"
              onDelete={() => handleDelete(item, index)}
              label={item}
            />
          ))}
        </div>
        <Input
          id="tags"
          name="tags"
          value={currValue}
          onChange={handleChange}
          onKeyDown={handleKeyUp}
          disableUnderline
          style={{ width: "100%" }}
          inputProps={{ maxLength: 25 }}
          helperText="Describe what your server is about, to allow users to search for you, in 1 or more tags. (up to 5) # are not needed 😃"
          disabled={disabledInput}
        />
      </FormControl>
      {formik?.errors.tags ? (
        <div className="form-error">{formik.errors.tags}</div>
      ) : null}

      <p>
        - Describe what your server is about, to allow users to search for you,
        in 1 or more tags. (up to 5) # are not needed 😃
      </p>
      <p>- Press enter between tags, andriod users please press #</p>
    </div>
  );
}

export default MultipleInput;
