import React, { Fragment } from "react";
import moment from "moment";

import addBotUrl from "../../../api/botUrl";

import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import "./DashboardCards.css";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export const AdminDisableMessage = () => {
  return (
    <Fragment>
      <div className="error-message-color">
        Your discord listing has been disabled by an admin, please contact
        support to find out more.
      </div>
    </Fragment>
  );
};

export const SyncEnabledMessage = () => {
  return (
    <Fragment>
      <div className="error-message-color">
        Please click on “Add bot” button. If you have already added our bot,
        please try running the “Sync” command.
      </div>
    </Fragment>
  );
};

export const GeneralMessage = () => {
  return (
    <Fragment>Don't forget you can /bump your server every 2 hours!</Fragment>
  );
};

export const DiscordUrlNotFoundMessage = () => {
  return (
    <Fragment>
      <div className="error-message-color">
        We noticed your invite link is not available. Please try running /invite
        in your server to fix that!
      </div>
    </Fragment>
  );
};

const DashboardCards = ({ server, handleEnableServer }) => {
  return (
    <div className="dashboard-card">
      <div className="d-card-header">
        <div className="d-card-image">
          <img
            src={
              server?.serverImageUrl
                ? server?.serverImageUrl
                : "https://cdn.discordapp.com/icons/977353635187019776/430bc9f2c448e55dc01098cae01216ac"
            }
            alt="card profile"
          />
        </div>
        <div className="d-card-header-right">
          <h2 className="d-card-title">{server?.name}</h2>
          <p className="last-bump-time">
            Last Bump:{" "}
            {server?.bumpTime
              ? moment(new Date(server.bumpTime))
                  .utc()
                  .local()
                  .format("DD-MM-YYYY HH:mm")
              : ""}
          </p>
        </div>
      </div>
      <div className="d-card-enabled">
        <FormControlLabel
          control={
            <Android12Switch
              checked={server?.disabled ? false : true}
              onChange={(e) => handleEnableServer(e, server.serverKey)}
            />
          }
          label={"Should the listing be visible publicly?"}
        />
      </div>
      <div className="d-card-message-panel">
        <div className="d-card-message">
          {server?.adminDisable && <AdminDisableMessage />}
          {!server?.syncEnabled && <SyncEnabledMessage />}
          {server?.syncEnabled && !server?.discordUrl && (
            <DiscordUrlNotFoundMessage />
          )}
          {server?.discordUrl &&
            !server?.adminDisable &&
            server?.syncEnabled && <GeneralMessage />}
        </div>
      </div>
      <div className="d-card-actions">
        {!server?.syncEnabled && (
          <a
            href={addBotUrl}
            target="_blank"
            rel="noreferrer"
            className="btn-add-bot"
          >
            Add Bot
          </a>
        )}
        <a href={"/server/" + server.url} className="btn-view">
          View
        </a>
        <a
          href={"/account/edit-server/" + server.serverKey}
          className="btn-edit"
        >
          Edit
        </a>
      </div>
    </div>
  );
};

export default DashboardCards;
