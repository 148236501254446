import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSearchTag } from "../../features/servers/serverSlice";

import SearchBar from "../search/SearchBar";
import "./HeroSection.css";

function HeroSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTagsClick = (tag) => {
    dispatch(setSearchTag(tag));
    navigate(`/tags/${tag.toLowerCase()}`);
  };

  return (
    <section>
      <div className="hero-section">
        <h1 className="hero-title">Discord Servers</h1>
        <p className="hero-text">Find Discord servers and make new friends!</p>

        <div className="search-section">
          <SearchBar />
        </div>

        <div className="hero-tags">
          <span onClick={() => handleTagsClick("Community")}>Community</span>
          <span onClick={() => handleTagsClick("Runescape")}>Runescape</span>
          <span onClick={() => handleTagsClick("Anime")}>Anime</span>
          <span onClick={() => handleTagsClick("Chill")}>Chill</span>
          <span onClick={() => handleTagsClick("Games")}>Games</span>
          <span onClick={() => handleTagsClick("NSFW")}>NSFW</span>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
