import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Popper from "@mui/material/Popper";

import { logoutUser } from "../../../features/auth/authSlice";
import {
  setSearchTag,
  setRandomSortFilter,
} from "../../../features/servers/serverSlice";

import discordSignInUrl from "../../../api/loginUrl";

import Logo from "../../../assets/logo/logo-light.png";
import AltProfileImage from "../../../assets/images/discord-profile-image.jpg";

import "./Header.css";

function Header() {
  const { loginToken, profileImage } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [openNav, setOpenNav] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClick = () => setOpenNav(!openNav);

  const handleClose = () => setOpenNav(!openNav);

  const handleShowDropdown = () => setShowDropdown(!showDropdown);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const routes = [
    {
      to: "/",
      text: "Home",
      onClick: () => dispatch(setRandomSortFilter()),
    },
    {
      to: "/leaderboard/servers",
      text: "Leaderboard",
      sublinks: [
        {
          to: "/leaderboard/servers",
          text: "Servers",
        },
        {
          to: "/leaderboard/users",
          text: "Users",
        },
      ],
    },
    {
      to: "/tags/community",
      text: "Tags",
      onClick: () => dispatch(setSearchTag("community")),
    },
    {
      condition: loginToken,
      to: "/account/myservers",
      text: "Dashboard",
    },
  ];

  return (
    <nav>
      <div className="header">
        <div className="header-left">
          <div className="logo">
            <Link to="/" onClick={() => dispatch(setRandomSortFilter())}>
              <img className="logo-image" src={Logo} alt="Logo" />
            </Link>
          </div>
          <div className="main-nav">
            <ul>
              {routes.map(
                ({
                  to,
                  text,
                  sublinks = false,
                  condition = true,
                  onClick = () => {},
                }) =>
                  condition && (
                    <HeaderLink
                      key={to + text}
                      to={to}
                      onClick={onClick}
                      sublinks={sublinks}
                    >
                      {text}
                    </HeaderLink>
                  )
              )}
            </ul>
          </div>
        </div>
        <div className="header-btns">
          {!loginToken && (
            <button className="sign-in">
              <a href={discordSignInUrl}>Sign In</a>
            </button>
          )}
          <a
            href={loginToken ? "/account/add-server" : discordSignInUrl}
            className="btn-add-server"
          >
            Add Server
          </a>
          {loginToken && (
            <div className="dropdown">
              <img
                src={profileImage ? profileImage : AltProfileImage}
                width="40"
                height="40"
                alt="User Profile"
                className="menu-profile"
                onClick={handleShowDropdown}
              />
              <i className="fa fa-caret-down" onClick={handleShowDropdown}></i>
              <div
                className={
                  !showDropdown
                    ? "dropdown-content"
                    : "dropdown-content show-dropdown-content"
                }
              >
                <a className="logout" onClick={handleLogout}>
                  Logout
                </a>
              </div>
            </div>
          )}
        </div>

        {/* Hamburger Menu */}
        {!loginToken && (
          <button
            className={!openNav ? "hamburger" : "open-hamburger hamburger"}
            onClick={handleClick}
          >
            <span className="hamburger-top"></span>
            <span className="hamburger-middle"></span>
            <span className="hamburger-bottom"></span>
          </button>
        )}
        {loginToken && (
          <img
            src={profileImage ? profileImage : AltProfileImage}
            width="40"
            height="40"
            alt="User Profile"
            className="mobile-profile"
            onClick={handleClick}
          />
        )}
      </div>

      {/* Mobile Menu */}
      <div className="mobile-menu">
        <ul className={!openNav ? "mobile-menu-closed" : "mobile-menu-open"}>
          <li>
            <a
              onClick={handleClose}
              href={loginToken ? "/account/add-server" : discordSignInUrl}
            >
              Add Server
            </a>
          </li>
          {!loginToken && (
            <li>
              <a onClick={handleClose} href={discordSignInUrl}>
                Login
              </a>
            </li>
          )}
          {/* <li><a onClick={handleClose} href="/">Premium</a></li> */}
          <li>
            <a onClick={handleClose} href="/tags">
              Tags
            </a>
          </li>
          {loginToken && (
            <li>
              <a href="/account/myservers">Dashboard</a>
            </li>
          )}
          {loginToken && (
            <li>
              <a onClick={handleLogout}>Logout</a>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
}

const HeaderLink = ({ sublinks, children, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const timeoutRef = useRef();

  const menuHover = (e) => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    sublinks && !anchorEl && setAnchorEl(e.currentTarget);
  };

  const menuLeave = (e) => {
    timeoutRef.current = setTimeout(() => setAnchorEl(null), [250]);
  };

  return (
    <li>
      <Link onMouseOver={menuHover} onMouseLeave={menuLeave} {...props}>
        {children}
      </Link>
      {sublinks && (
        <Popper
          onMouseOver={menuHover}
          onMouseLeave={menuLeave}
          className="link-popper link-dropdown-content"
          open={anchorEl}
          anchorEl={anchorEl}
        >
          {sublinks.map(({ to, text, onClick = () => {} }) => (
            <Link className={"sublink"} to={to} onClick={onClick}>
              {text}
            </Link>
          ))}
        </Popper>
      )}
    </li>
  );
};

export default Header;
