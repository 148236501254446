import React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setSearchTag } from "../../features/servers/serverSlice";

import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";
import Seo from "../../components/common/Seo/Seo";
import TagsHero from "../../components/TagsHero/TagsHero";
import TagsFilterSection from "../../components/FilterSection/TagsFilterSection";
import CardContainer from "../../components/cards/CardContainer/CardContainer";
import { tagUrlInverterConverter } from "../../components/common/TagUtils";

function formatString(str) {
  return str
    .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
    .replace(/^[^ ]/g, (match) => match.toUpperCase());
}

const TagPage = () => {
  const dispatch = useDispatch();
  let { searchTerm } = useParams();
  searchTerm = tagUrlInverterConverter(searchTerm);

  const { totalItems, searchTag } = useSelector((state) => state.servers);

  let newSearchTerm;

  if (searchTerm.toLowerCase() !== searchTag.toLowerCase()) {
    dispatch(setSearchTag(searchTerm));
    newSearchTerm = formatString(searchTerm);
  } else {
    newSearchTerm = searchTag;
  }

  document.title =
    searchTag.charAt(0).toUpperCase() + searchTag.slice(1) + " Discord Servers";

  return (
    <div className="tag-page">
      <Header />
      <TagsHero totalItems={totalItems} searchTag={newSearchTerm} />
      <TagsFilterSection />
      <Seo />
      <CardContainer page="tagPage" />
      <Footer />
    </div>
  );
};

export default TagPage;
