import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import "./Dashboard.css";

import {
  getMyServers,
  enableDisableServer,
  syncServer,
} from "../../features/myServers/myServerSlice";

// components
import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";
import DashboardCard from "../../components/Dashboard/DashboardCards/DashboardCards";

function Dashboard() {
  const dispatch = useDispatch();

  const { myServers, isSyncing, isLoading, syncSuccess } = useSelector(
    (state) => state.myServer
  );

  useEffect(() => {
    dispatch(getMyServers());
  }, [syncSuccess, dispatch]);

  const handleEnableServer = (e, serverKey) => {
    let status;
    const body = {
      serverKey: serverKey,
    };
    if (e.target.checked === false) {
      status = "disable";
      console.log("Status is Enabled!");
    } else {
      status = "enable";
      console.log("Status is Disabled!");
    }
    dispatch(enableDisableServer({ body, status }));
  };

  return (
    <Box className="dashboard-container">
      <Header />
      <div className="dashboard-pane">
        <div className="dashboard-header">
          <h1>My Servers</h1>
          <div className="dashboard-header-actions">
            <a
              onClick={() => dispatch(syncServer())}
              className="btn-dashboard-header"
            >
              {isSyncing ? "Syncing..." : "Sync"}
            </a>
            <a href="/account/add-server" className="btn-dashboard-header">
              Add Server
            </a>
          </div>
        </div>
        <div className="dashboard-cards">
          <div className="dashboard-card-items">
            {myServers?.length ? (
              myServers.map((server) => {
                return (
                  <DashboardCard
                    key={server.serverKey}
                    server={server}
                    handleEnableServer={handleEnableServer}
                  />
                );
              })
            ) : isLoading ? (
              <Box
                sx={{
                  marginTop: "100px",
                  marginBottom: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <p className="no-cards">There are no servers to display</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Box>
  );
}

export default Dashboard;
