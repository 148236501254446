import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import myServerService from "./myServerService";
import { toast } from "react-toastify";

// Get voteStatus from localStorage
// const voteStatus = localStorage.getItem("voteStatus");

const initialState = {
  myAvailableServers: [],
  myServers: [],
  singleServer: [],
  isError: false,
  isSuccess: false,
  syncSuccess: false,
  isSyncing: false,
  isSavedSuccess: false,
  isLoading: false,
  message: "",
};

export const getAvailableServers = createAsyncThunk(
  "myServers/getAvailableServers",
  async (_, thunkAPI) => {
    try {
      return await myServerService.getAvailableServers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMyServers = createAsyncThunk(
  "myServers/getMyServers",
  async (_, thunkAPI) => {
    try {
      return await myServerService.getMyServers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createMyServer = createAsyncThunk(
  "myServers/createMyServer",
  async (server, thunkAPI) => {
    try {
      return await myServerService.createMyServer(server);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSingleServer = createAsyncThunk(
  "myServers/getSingleServer",
  async (serverKey, thunkAPI) => {
    try {
      return await myServerService.getSingleServer(serverKey);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateMyServer = createAsyncThunk(
  "myServers/updateMyServer",
  async (server, thunkAPI) => {
    try {
      return await myServerService.updateMyServer(server);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const enableDisableServer = createAsyncThunk(
  "myServers/enableDisableServer",
  async (reqParams, thunkAPI) => {
    try {
      return await myServerService.enableDisableServer(reqParams);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const syncServer = createAsyncThunk(
  "myServers/syncServer",
  async (_, thunkAPI) => {
    try {
      return await myServerService.syncServer();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const myServerSlice = createSlice({
  name: "myServer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableServers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAvailableServers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.myAvailableServers = action.payload.data;
      })
      .addCase(getAvailableServers.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getMyServers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMyServers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.syncSuccess = false;
        state.isError = false;
        state.myServers = action.payload.data;
      })
      .addCase(getMyServers.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(createMyServer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMyServer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isSavedSuccess = true;
        state.isError = false;
        toast.success(`Server saved successfully!`, {
          position: "bottom-left",
        });
      })
      .addCase(createMyServer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getSingleServer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleServer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singleServer = action.payload.data;
      })
      .addCase(getSingleServer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateMyServer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMyServer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isSavedSuccess = true;
        state.isError = false;
        toast.success(`Server updated successfully!`, {
          position: "bottom-left",
        });
      })
      .addCase(updateMyServer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(enableDisableServer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(enableDisableServer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        const itemIndex = state.myServers.findIndex(
          (item) => item.serverKey === action.payload.serverKey
        );

        state.myServers[itemIndex].disabled = action.payload.disabled;
        toast.success(
          `Server ${
            action.payload.disabled === true ? "disabled" : "enabled"
          } successfully!`,
          {
            position: "bottom-left",
          }
        );
      })
      .addCase(enableDisableServer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(
          `The action cannot be completed. Please contact support for any issues.`,
          {
            position: "bottom-left",
          }
        );
      })

      .addCase(syncServer.pending, (state) => {
        state.isLoading = true;
        state.isSyncing = true;
      })
      .addCase(syncServer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSyncing = false;
        state.isSuccess = true;
        state.syncSuccess = true;
        state.isError = false;
        toast.success(`Server sync was successful!`, {
          position: "bottom-left",
        });
      })
      .addCase(syncServer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSyncing = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(
          `Something went wrong. Please contact support for any issues.`,
          {
            position: "bottom-left",
          }
        );
      });
  },
});

export default myServerSlice.reducer;
