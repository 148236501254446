import instance from "../../api/axiosConfig";
import Cookies from "js-cookie";

const API_URL = "/api/v1/discord/login";

const loginUser = async (payload) => {
  const response = await instance.post(API_URL, payload);
  console.log("Login Response: ", response);
  return response;
};

// Logout user
const logoutUser = (jwtTokenName) => {
  Cookies.remove(jwtTokenName, { path: "/" });
  localStorage.removeItem("profileImage");
};

const getProfileImage = async () => {
  const response = await instance.get("/api/v1/secure/discord/user");
  localStorage.setItem(
    "profileImage",
    JSON.stringify(response.data.avatarImageUrl)
  );
  return response.data;
};

const initVoteLogin = async (payload) => {
  return await instance.post(API_URL + "/init", payload);
};

const authService = {
  loginUser,
  logoutUser,
  getProfileImage,
  initVoteLogin,
};

export default authService;
