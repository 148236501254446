import React from "react";

import "./CreateServerPreview.css";
import PreviewCard from "./PreviewCard";

function CreateServerPreview({ formik, myAvailableServers, singleServer }) {
  let serverImage;

  if (singleServer) {
    serverImage = singleServer.serverImageUrl;
  } else if (formik.values.discordId.length > 0) {
    serverImage = myAvailableServers.find(
      (server) => server.id === formik.values.discordId
    ).icon;
  } else {
    serverImage = null;
  }

  return (
    <div className="server-preview-container">
      <h3 className="preview-container-title">Preview</h3>

      <PreviewCard
        server={formik.values}
        serverImage={serverImage}
        singleServer={singleServer}
      />
    </div>
  );
}

export default CreateServerPreview;
