import React from "react";

import "./CreateServerForm.css";
import MultipleInput from "./MultipleInput";

function CreateServerForm({
  formik,
  myAvailableServers,
  values,
  setValues,
  status,
}) {
  return (
    <div className="server-form-container">
      <form onSubmit={formik.handleSubmit} className="server-form">
        <h3 className="form-title">
          {status === "add" ? "Create " : "Update "} Info
        </h3>

        {status === "add" && (
          <div
            className={
              formik.touched.discordId && formik.errors.discordId
                ? "form-control error"
                : "form-control form-control-select"
            }
          >
            <label htmlFor="discordId">Discord Server</label>
            <select
              className="create-server-select"
              id="discordId"
              name="discordId"
              {...formik.getFieldProps("discordId")}
            >
              <option value="">Please Select a Discord Server</option>
              {myAvailableServers.length > 0 &&
                myAvailableServers.map((server) => (
                  <option
                    key={server.id}
                    value={server.id}
                    style={{ backgroundImage: `url(${server.icon})` }}
                  >
                    {server.name}
                  </option>
                ))}
            </select>
          </div>
        )}

        <div
          className={
            formik.touched.name && formik.errors.name
              ? "form-control error"
              : "form-control"
          }
        >
          <label htmlFor="name">Server Name</label>
          <input
            id="name"
            name="name"
            type="text"
            disabled
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="form-error">{formik.errors.name}</div>
          ) : null}
        </div>

        <div
          className={
            formik.touched.serverPageDescription &&
            formik.errors.serverPageDescription
              ? "form-control error"
              : "form-control"
          }
        >
          <label htmlFor="serverPageDescription">Description</label>
          <textarea
            id="serverPageDescription"
            name="serverPageDescription"
            type="text"
            rows="5"
            {...formik.getFieldProps("serverPageDescription")}
          />
          {formik.touched.serverPageDescription &&
          formik.errors.serverPageDescription ? (
            <div className="form-error">
              {formik.errors.serverPageDescription}
            </div>
          ) : null}
          <span class="help-block">
            - Only the first 700 characters will show up on the preview card.
            Any additional words will appear on your server page.
          </span>
        </div>

        <MultipleInput values={values} setValues={setValues} formik={formik} />

        <div className="form-control nsfw-checkbox">
          <label htmlFor="nsfw">Not Safe for Work</label>

          <input
            id="nsfw"
            name="nsfw"
            type="checkbox"
            checked={formik.getFieldProps("nsfw").value}
            {...formik.getFieldProps("nsfw")}
          />
          <span className="nsfw-checkmark"></span>
        </div>

        <div className="form-control">
          <label htmlFor="websiteUrl">Website URL (Optional)</label>
          <input
            id="websiteUrl"
            name="websiteUrl"
            type="websiteUrl"
            {...formik.getFieldProps("websiteUrl")}
          />
        </div>

        <div className="btn-form-control">
          <button type="submit" className="btn-save-server">
            Save Server
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateServerForm;
