import React from "react";
import Switch from "./Switch";
import Filter from "./Filter";

import "./FilterSection.css";

function FilterSection({ handleToggleChange, sortFilter }) {
  return (
    <div className="filter-section">
      <div className="filter-switch">
        <Switch
          handleToggleChange={handleToggleChange}
          sortFilter={sortFilter}
        />
      </div>
      <Filter />
    </div>
  );
}

export default FilterSection;
