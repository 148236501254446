import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

// Components
import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";

import {
  setDiscordResponseCode,
  setDiscordResponseState,
  loginUser,
  setAuthCookie,
  getUserProfileImage,
} from "../../features/auth/authSlice";

function LoginRedirect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const codeValue = searchParams.get("code");
  const discordStateValue = searchParams.get("state");

  const {
    isError,
    code,
    discordState,
    loginSuccess,
    message,
    loginToken,
    redirectLocation,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (codeValue) {
      dispatch(setDiscordResponseCode(codeValue));
    }
    if (discordStateValue) {
      dispatch(setDiscordResponseState(discordStateValue));
    }
  }, [codeValue, discordStateValue, dispatch]);

  useEffect(() => {
    if (discordState && code) {
      dispatch(
        loginUser({
          state: discordState,
          code: code,
        })
      );
    } else if (code) {
      dispatch(loginUser({ code: code }));
    }
  }, [code, discordState, dispatch]);

  useEffect(() => {
    if (isError) {
      console.log(message);
      navigate("/");
    }
  }, [isError, message, navigate]);

  useEffect(() => {
    if (redirectLocation?.length > 0) {
      dispatch(setAuthCookie());
      dispatch(getUserProfileImage());
      navigate(redirectLocation);
    }

    if (loginSuccess) {
      dispatch(setAuthCookie());
      dispatch(getUserProfileImage());
      navigate("/account/myservers");
    }
  }, [loginSuccess, redirectLocation, navigate, dispatch]);

  return (
    <Box className="login-container">
      <Header />
      <Box
        sx={{
          margin: "auto",
          py: 12,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress size="150px" />
      </Box>
      <Footer />
    </Box>
  );
}

export default LoginRedirect;
