import React, { useEffect } from "react";
import Box from "@mui/material/Box";
// import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from "react-redux";
import {
  setRandomSortFilter,
  setSortFilter,
} from "../../features/servers/serverSlice";

// Components
import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";
import HeroSection from "../../components/Hero/HeroSection";
import FilterSection from "../../components/FilterSection/FilterSection";
import CardContainer from "../../components/cards/CardContainer/CardContainer";
import ReactGA from "react-ga4";

function Home() {
  const dispatch = useDispatch();

  const { sortFilter } = useSelector((state) => state.servers);

  useEffect(() => {
    dispatch(setRandomSortFilter());
  }, [dispatch]);

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  const handleToggleChange = (e) => {
    if (e?.target?.value) {
      if (e.target.checked) {
        dispatch(setSortFilter("MOST_ACTIVE"));
      } else {
        dispatch(setSortFilter("BUMP"));
      }
    }
  };

  return (
    <Box className="home-container">
      <Header />
      <HeroSection />
      <FilterSection
        handleToggleChange={handleToggleChange}
        sortFilter={sortFilter}
      />
      <CardContainer />
      <Footer />
    </Box>
  );
}

export default Home;
