import { configureStore } from "@reduxjs/toolkit";
import serverReducer from "../features/servers/serverSlice";
import authReducer from "../features/auth/authSlice";
import myServerReducer from "../features/myServers/myServerSlice";
import leaderboardReducer from "../features/leaderboard.js/leaderboardSlice";

export const store = configureStore({
  reducer: {
    servers: serverReducer,
    auth: authReducer,
    myServer: myServerReducer,
    leaderboard: leaderboardReducer,
  },
});
