import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import {
  updateMyServer,
  getSingleServer,
  getAvailableServers,
} from "../../features/myServers/myServerSlice";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import CreateServerForm from "../../components/AddServer/CreateServer/CreateServerForm";
import CreateServerPreview from "../../components/AddServer/PreviewServer/CreateServerPreview";

import "./EditServer.css";
import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";

function EditServer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serverKey } = useParams();
  const [values, setValues] = useState([]);

  useEffect(() => {
    dispatch(getAvailableServers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSingleServer(serverKey));
  }, [serverKey, dispatch]);

  const {
    isLoading,
    isError,
    message,
    isSavedSuccess,
    myAvailableServers,
    singleServer,
  } = useSelector((state) => state.myServer);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      discordId: singleServer.discordId ? singleServer.discordId : "",
      name: singleServer.name ? singleServer.name : "",
      tags: [],
      serverPageDescription: singleServer.serverPageDescription
        ? singleServer.serverPageDescription
        : "",
      nsfw: singleServer.nsfw ? singleServer.nsfw : "",
      websiteUrl: singleServer.websiteUrl ? singleServer.websiteUrl : "",
    },
    validationSchema: Yup.object({
      discordId: Yup.string().required("This field is required"),
      name: Yup.string()
        .max(30, "Title must be less than 30 characters")
        .required("Please provide a name for your server"),
      serverPageDescription: Yup.string().required(
        "Please provide a description"
      ),
      tags: Yup.array()
        .min(1, "Please provide atleast 1 tag")
        .required("Please provide atleast 1 tag"),
    }),
    onSubmit: (values) => {
      const updatedServerValues = JSON.stringify(values, null, 2);
      console.log(updatedServerValues);
      dispatch(updateMyServer(updatedServerValues));
    },
  });

  const setInitialTags = () => {
    let new_values;
    if (singleServer.tags) {
      new_values = singleServer.tags.map((tag) => tag.name);
    }
    setValues(new_values);
  };

  const populateTags = (tags) => {
    const res = [];
    if (tags?.length > 0) {
      tags.map((tag) => res.push({ name: tag }));
      formik.setFieldValue("tags", res, false);
    } else {
      formik.setFieldValue("tags", res, false);
    }
  };

  useEffect(() => {
    if (isError) {
      console.log(message);
    }

    if (singleServer?.tags) {
      setInitialTags();
    }
  }, [isError, message, singleServer.tags, dispatch]);

  useEffect(() => {
    populateTags(values);
  }, [values]);

  useEffect(() => {
    if (isSavedSuccess) {
      navigate("/account/myservers");
    }
  }, [isSavedSuccess, navigate]);

  return (
    <div className="add-server">
      <Header />
      <div className="create-server-container">
        <div>
          <h1>Update Server</h1>
        </div>

        {isLoading ? (
          <Box
            sx={{
              marginY: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className="create-server-section">
            <CreateServerForm
              formik={formik}
              populateTags={populateTags}
              myAvailableServers={myAvailableServers}
              values={values}
              setValues={setValues}
            />

            <CreateServerPreview
              formik={formik}
              myAvailableServers={myAvailableServers}
              singleServer={singleServer}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default EditServer;
