import React from "react";
import "./Footer.css";
import Logo from "../../../assets/logo/logo-light.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

library.add(faFacebook, faTwitter, faInstagram, faLinkedin);

function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-top">
          <div className="left">
            <img className="footer-logo-image" src={Logo} alt="logo" />
          </div>
          <div className="right">
            <ul>
              <li>
                <a
                  href="https://discord.gg/cEth4pnxbc"
                  target="_blank"
                  rel="noreferrer"
                >
                  Support
                </a>
              </li>
              <li>
                <a href="/">About Us</a>
              </li>
              <li>
                <a href="/commands">Bot commands</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://revote.gg/Cookies_Policy_Revote.pdf">
                  Cookie Policy
                </a>
              </li>
              <li>
                <a href="https://revote.gg/Terms_and_Conditions_Revote.pdf">
                  Terms &amp; Conditions
                </a>
              </li>
              <li>
                <a href="https://revote.gg/Privacy_Policy_Revote.pdf">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="main-hr" />
        <div className="footer-bottom">
          <div className="copyright">
            &copy;Copyright 2022 Revote: This site is not affiliated with
            Discord
          </div>
          <hr className="copyright-hr" />
          <div className="social-media">
            <span>
              <FontAwesomeIcon icon="fa-brands fa-linkedin" />
            </span>
            <span>
              <FontAwesomeIcon icon="fa-brands fa-facebook" />
            </span>
            <span>
              <FontAwesomeIcon icon="fa-brands fa-instagram" />
            </span>
            <span>
              <FontAwesomeIcon icon="fa-brands fa-twitter" />
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
