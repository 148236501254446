import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlayerHistory,
  getTopVoters,
  getVoterEligibility,
  setUserVote,
} from "../../features/servers/serverSlice";
import { initVoteLogin } from "../../features/auth/authSlice";

import discordSignInUrl from "../../api/loginUrl";

import { toast } from "react-toastify";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from "react-chartjs-2";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import "./ViewServerBody.css";
import { Typography } from "@mui/material";
import PlayerHistoryChart from "./PlayerHistoryChart";
import { tagUrlConverter } from "../common/TagUtils";

const VoterDetailsCard = ({ profileImage, name, votes }) => {
  return (
    <div className="voter-details">
      <img src={profileImage} alt="Voter image" />
      <span className="name">{name ? name : ""}</span>
      <span className="votes">{votes}</span>
    </div>
  );
};

const initialChart = {
  labels: [],
  showTooltips: true,
  datasets: [
    {
      label: "Current Week",
      data: [],
      fill: false,
      backgroundColor: "rgb(25, 118, 210, 0.2)",
      borderColor: "rgb(25, 118, 210, 1)",
    },
    {
      label: "Previous Week",
      data: [],
      fill: false,
      backgroundColor: "rgb(25, 118, 210, 0.2)",
      borderColor: "rgb(25, 118, 210, 1)",
    },
  ],
};

function ViewServerBody({ serverDetails }) {
  const dispatch = useDispatch();

  const [chart, setChart] = useState(initialChart);

  const { playerHistory, topVoters, eligibleToVote, voteStatus } = useSelector(
    (state) => state.servers
  );
  const { isLoading, loginToken, loginState } = useSelector(
    (state) => state.auth
  );

  const handleSubmitVote = (serverKey) => {
    if (!loginToken) {
      const payload = {
        serverVoteRedirect: true,
        serverKey,
      };
      dispatch(initVoteLogin(payload));
    } else if (eligibleToVote && !voteStatus) {
      dispatch(setUserVote({ serverKey: serverKey }));
    } else {
      toast.error(`Error submitting vote, please contact support.`, {
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    if (serverDetails?.serverKey) {
      dispatch(getPlayerHistory(serverDetails.serverKey));
      dispatch(getTopVoters(serverDetails.serverKey));
      loginToken && dispatch(getVoterEligibility());
    }
  }, [dispatch, serverDetails.serverKey, loginToken]);

  // replace newPlayerHistory with playerHistory when data is available.
  useEffect(() => {
    if (playerHistory?.serverStatsCurrentWeek?.length > 0) {
      let onlinePlayersCurrentWeek = playerHistory?.serverStatsCurrentWeek?.map(
        (e) => e.onlinePlayers
      );
      let creationDateCurrentWeek = playerHistory?.serverStatsCurrentWeek?.map(
        (e) => e.creationDate
      );
      let onlinePlayersPreviousWeek =
        playerHistory?.serverStatsPreviousWeek?.map((e) => e.onlinePlayers);

      setChart({
        labels: Object.values(creationDateCurrentWeek),
        showTooltips: true,
        datasets: [
          {
            label: "Current Week",
            data: Object.values(onlinePlayersCurrentWeek),
            fill: true,
            backgroundColor: "rgb(74, 86, 255, 0.2)",
            borderColor: "rgb(74, 86, 255, 1)",
            borderWidth: 2,
          },
          {
            label: "Previous Week",
            data: Object.values(onlinePlayersPreviousWeek),
            fill: true,
            backgroundColor: "rgb(213, 81, 157, 0.2)",
            borderColor: "rgb(213, 81, 157, 1)",
            borderWidth: 2,
          },
        ],
      });
    }
  }, [playerHistory]);

  useEffect(() => {
    if (loginState) {
      window.location.href = discordSignInUrl + `&state=${loginState}`;
    }
  }, [loginState]);

  if (isLoading) {
    return (
      <Box
        sx={{
          marginY: 24,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="view-server-body">
      <div className="view-server-body-left-pane">
        <div className="server-image">
          <img
            src={serverDetails?.serverImageUrl + "?size=512"}
            alt={"Logo of the server " + serverDetails?.name}
          />
        </div>
        <div className="about-server">
          <h3>About {serverDetails?.name}</h3>
          <Typography>{serverDetails?.serverPageDescription}</Typography>
        </div>

        {/* Using ChartJS */}
        <div className="player-history">
          <PlayerHistoryChart data={chart} />
        </div>
      </div>
      <div className="view-server-body-right-pane">
        <div className="server-vote">
          <h2>Vote</h2>
          <button
            // className={`btn-server-vote ${eligibleToVote && !voteStatus ? '' : 'disabled'}`}
            className={`btn-server-vote ${
              !loginToken ? "" : eligibleToVote && !voteStatus ? "" : "disabled"
            }`}
            title={
              eligibleToVote && !voteStatus
                ? ""
                : "You are not eligible to vote!"
            }
            onClick={() => handleSubmitVote(serverDetails?.serverKey)}
          >
            Vote Now
          </button>
        </div>
        <div className="server-informations">
          <h2>Informations</h2>
          <div className="info-body">
            <div className="info-row">
              <h4>Disabled</h4>
              <p>: {serverDetails?.disabled ? "Yes" : "No"}</p>
            </div>
            <div className="info-row">
              <h4>Online</h4>
              <p>: {serverDetails?.onlineMemberCount}</p>
            </div>
            <div className="info-row">
              <h4>Total</h4>
              <p>: {serverDetails?.totalMemberCount}</p>
            </div>
            <div className="info-row">
              <h4>Votes</h4>
              <p>: {serverDetails?.votes}</p>
            </div>
          </div>
          <div className="server-tags">
            {serverDetails.tags?.length
              ? serverDetails.tags.map((tag, key) => (
                  <span key={key}>
                    <a
                      href={`/tags/${tagUrlConverter(tag.name.toLowerCase())}`}
                    >
                      {tag.name}
                    </a>
                  </span>
                ))
              : null}
          </div>
          <div className="info-actions">
            <a
              href={serverDetails?.discordUrl}
              target="_blank"
              className="btn-join-discord"
            >
              Join Discord
            </a>
            <a
              href={serverDetails?.websiteUrl}
              target="_blank"
              className="btn-website-url"
            >
              Website URL
            </a>
          </div>
        </div>
        <div className="server-top-voters">
          <h3>Top Voters</h3>
          <div className="top-voters-body">
            {topVoters?.length > 0 ? (
              topVoters?.map((voter, key) => (
                <VoterDetailsCard
                  key={key}
                  name={voter.username}
                  profileImage={voter.avatarImageUrl}
                  votes={voter.totalVotes}
                />
              ))
            ) : (
              <p>Be the first one to vote!</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewServerBody;
