import "./Seo.css";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getServersSeo } from "../../../features/servers/serverSlice";

function Seo() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServersSeo());
  }, [dispatch]);

  const { seo } = useSelector((state) => state.servers);

  const { searchTag } = useSelector((state) => state.servers);

  let description = "";
  if (seo === "") {
    return (
      <Helmet>
        <meta
          name="description"
          content={
            "We have plenty of Discord Servers that use the tag " +
            searchTag +
            ". Utilize our amazing Search Engine on our website, to find a server just right for you!"
          }
        />
      </Helmet>
    );
  } else {
    description = seo?.substring(0, 160);
  }

  return (
    <div className="seo">
      <Helmet>
        <meta name="description" content={description} />
      </Helmet>
      <p>{seo}</p>
    </div>
  );
}

export default Seo;
