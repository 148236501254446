import instance from "../../api/axiosConfig";

const API_URL = "/api/v1/discord/search/leaderboard";
const TOP_SERVERS_API = `${API_URL}/top/servers`;
const TOP_USERS_URL = `${API_URL}/top/users`;

const getTopServers = async (pageParams) => await instance.get(TOP_SERVERS_API);
const getTopUsers = async (pageParams) => await instance.get(TOP_USERS_URL);

const leaderboardService = {
  getTopServers,
  getTopUsers,
};

export default leaderboardService;
