import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faShare } from "@fortawesome/free-solid-svg-icons";

import "./Filter.css";
import FilterIcon from "../../assets/images/filter-icon.svg";

library.add(faShare);

function Filter() {
  return (
    <div className="filter-container">
      <div className="filter">
        <img className="filter-icon" src={FilterIcon} alt="Filter icon" />
        <span className="filter-text">Filter</span>
      </div>

      <a
        href="https://discord.gg/revote"
        target="_blank"
        rel="noreferrer"
        className="join-discord"
      >
        <span className="join-discord-text">Join our Discord</span>
        <FontAwesomeIcon className="join-discord-icon" icon="fas fa-share" />
      </a>
    </div>
  );
}

export default Filter;
