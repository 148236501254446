import React from "react";

import "./Card.css";

export const CardHeader = ({ img, title, tags = [] }) => (
  <div className="card-header">
    {img && (
      <div className="card-image">
        <img src={img.url} alt={img.name} />
      </div>
    )}
    <div className="header-right">
      <h2 className="card-title">{title}</h2>
      <div className="title-tags">
        {tags.map((tag, key) => {
          if (tag.name === "PREMIUM") {
            return <span className="premium">{tag.name}</span>;
          }
          return <span>{tag.name}</span>;
        })}
      </div>
    </div>
  </div>
);

export const CardContent = ({ children }) => (
  <div className="card-content">{children}</div>
);

const Card = ({ className, children }) => (
  <div className={`card ${className ?? ""}`}>{children}</div>
);

export default Card;
