import instance from "../../api/axiosConfig";

const API_URL = "/api/v1/discord/search/";
const PLAYER_HISTORY_API_URL =
  "/api/v1/discord/server/analytics/player-history";
const TOP_VOTERS_API_URL = "/api/v1/discord/vote/top";
const VOTE_API_URL = "/api/v1/secure/discord/vote";

// GET homepage Servers
const getServers = async (pageParams) => {
  const { currentPage, pageSize, searchTag, sortFilter } = pageParams;

  var response;

  if (searchTag) {
    const payload = {
      page: currentPage,
      size: pageSize,
      tag: searchTag,
    };

    response = await instance.post(API_URL + "tag", payload);
  } else {
    const payload = {
      page: currentPage,
      size: pageSize,
      sortFilter: sortFilter,
    };

    response = await instance.post(API_URL + "homepage", payload);
  }

  return response;
};

const getSeo = async (searchTag) => {
  const response = await instance.get(
    "/api/v1/discord/tag/seo?tagname=" + searchTag
  );
  return response.data;
};

const viewServer = async (serverUrl) => {
  const response = await instance.post(API_URL + "server", serverUrl);
  return response.data;
};

const getPlayerHistory = async (serverKey) => {
  const response = await instance.get(
    PLAYER_HISTORY_API_URL + "?serverkey=" + serverKey
  );
  return response.data;
};

const getTopVoters = async (serverKey) => {
  const response = await instance.get(
    TOP_VOTERS_API_URL + "?serverkey=" + serverKey
  );
  return response.data;
};

const getVoterEligibility = async () => {
  const response = await instance.get(VOTE_API_URL + "/eligible");
  return response.data;
};

const setUserVote = async (serverKey) => {
  const response = await instance.post(VOTE_API_URL, serverKey);
  // localStorage.setItem("voteStatus", true);
  return response.data;
};

const serverService = {
  getServers,
  getTopVoters,
  getPlayerHistory,
  viewServer,
  getVoterEligibility,
  setUserVote,
  getSeo,
};

export default serverService;
