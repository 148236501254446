import React from "react";
import { usePagination, DOTS } from "./usePagination";

import "./Pagination.css";

const Pagination = ({
  totalCount,
  currentPage,
  pageSize,
  totalPages,
  onPageChange,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: 1,
    pageSize,
  });

  // Return null when there is less than 2 pages
  if (currentPage < 0 || totalPages < 2) {
    return null;
  }

  // Increment page number on click Next
  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  // Decrement page number on click Prev
  const onPrev = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[totalPages - 1];

  return (
    <ul className="pagination-container">
      {/* Left navigation arrow */}
      <li
        className={`pagination-item ${currentPage === 0 ? "disabled" : ""}`}
        onClick={onPrev}
      >
        <div className="arrow left" />
      </li>

      {paginationRange.map((pageNumber, key) => {
        // Render the DOTS unicode character is the pageNumber is a DOT
        if (pageNumber === DOTS) {
          return (
            <li className="pagination-item dots" key={key}>
              &#8230;
            </li>
          );
        }

        // Render the page Pills
        return (
          <li
            className={`pagination-item ${
              pageNumber === currentPage + 1 ? "active disabled" : ""
            }`}
            onClick={() => onPageChange(pageNumber - 1)}
            key={key}
          >
            {pageNumber}
          </li>
        );
      })}

      {/* Right navigation arrow */}
      <li
        className={`pagination-item ${
          currentPage + 1 === lastPage ? "disabled" : ""
        }`}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
