import React from "react";

import "./PreviewCard.css";
import Profile from "../../../assets/images/Profile.png";

function PreviewCard({ server, serverImage, singleServer, handleTagsClick }) {
  return (
    <div className="preview-card">
      <div className="card-header">
        <div className="card-image">
          <img
            src={
              serverImage
                ? serverImage
                : "https://cdn.discordapp.com/icons/977353635187019776/430bc9f2c448e55dc01098cae01216ac"
            }
            alt="card profile"
          />
        </div>
        <div className="header-right">
          <h2 className="card-title">{server.name}</h2>
          <div className="title-tags">
            <span>NEW</span>
          </div>
        </div>
      </div>
      <div className="card-content">
        <p>
          {server.serverPageDescription.length > 750
            ? server.serverPageDescription.slice(0, 700) + "..."
            : server.serverPageDescription}
        </p>
      </div>
      <div className="card-tags">
        {server.tags?.length
          ? server.tags.map((tag, key) => (
              <span key={key} onClick={() => handleTagsClick(tag.name)}>
                {tag.name.toUpperCase()}
              </span>
            ))
          : null}
      </div>
      <div className="card-stats">
        <div className="number-of-users">
          {/* <img src={Profile} alt="users" /> */}
          <span className="online-indicator"></span>
          <span>
            {singleServer?.onlineMemberCount
              ? singleServer.onlineMemberCount
              : "999"}
          </span>
        </div>
        <div className="diamond-size">
          <img src={Profile} alt="users" />
          <span>
            {singleServer?.totalMemberCount
              ? singleServer.totalMemberCount
              : "999"}
          </span>
        </div>
        <div className="more-stats">
          <a
            href={`https://dev.revote.gg/server/${server.url}`}
            target="_blank"
            rel="noreferrer"
            className="btn-vote-now"
          >
            Vote Now
          </a>
        </div>
      </div>
      <div className="card-action">
        <span className="btn-card-action join">
          <a href={server.discordUrl} target="_blank" rel="noreferrer">
            Join Discord
          </a>
        </span>
        <a
          href={`https://dev.revote.gg/server/${server.url}`}
          target="_blank"
          rel="noreferrer"
          className="btn-card-action server-list"
        >
          See More
        </a>
      </div>
    </div>
  );
}

export default PreviewCard;
