import instance from "../../api/axiosConfig";

const API_URL = "/api/v1/secure/discord/account/server/";
// const UPDATE_API_URL = `/api/v1/secure/discord/account/server/edit/info?serverkey="String"`

const getAvailableServers = async () => {
  const response = await instance.get(`${API_URL}available`);
  return response;
};

const getMyServers = async () => {
  const response = await instance.get(API_URL);
  return response;
};

const createMyServer = async (server) => {
  console.log("New Server: ", server);
  const response = await instance.post(API_URL, server);
  return response;
};

const getSingleServer = async (serverKey) => {
  const response = await instance.get(
    `${API_URL}edit/info?serverkey=${serverKey}`
  );
  return response;
};

const updateMyServer = async (server) => {
  const response = await instance.put(API_URL, server);
  return response;
};

const enableDisableServer = async (reqParams) => {
  const { body, status } = reqParams;
  const response = await instance.post(API_URL + status, body);
  return response.data;
};

const syncServer = async () => {
  const response = await instance.post(API_URL + "sync");
  return response.data;
};

const myServerService = {
  getAvailableServers,
  createMyServer,
  updateMyServer,
  getSingleServer,
  getMyServers,
  enableDisableServer,
  syncServer,
};

export default myServerService;
