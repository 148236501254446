import React from "react";

import "./Switch.css";

function Switch({ handleToggleChange, sortFilter }) {
  return sortFilter ? (
    <div className="switch-container">
      <span className="switch">
        <input
          type="checkbox"
          id="switcher"
          checked={sortFilter === "BUMP" ? false : true}
          onChange={handleToggleChange}
        />
        <label htmlFor="switcher"></label>
      </span>
    </div>
  ) : null;
}

export default Switch;
