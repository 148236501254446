import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";

// Single Server actions
import { viewServer, setSearchTag } from "../../features/servers/serverSlice";

import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import ViewServerBody from "../../components/ViewServer/ViewServerBody";
import ReactGA from "react-ga4";
import "./ViewServer.css";

function ViewServer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serverUrl } = useParams();

  const { isLoading, isError, viewState, message, serverDetails } = useSelector(
    (state) => state.servers
  );

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  useEffect(() => {
    dispatch(viewServer({ url: serverUrl }));
  }, [dispatch, serverUrl]);

  useEffect(() => {
    if (isError && viewState) {
      console.log(message);
      navigate("/account/myservers");
    }
  }, [isError, viewState, message, navigate]);

  // const handleTagsClick = (tag) => {
  //     dispatch(setSearchTag(tag))
  //     navigate(`/tags/${tag.toLowerCase()}`);
  // }

  if (isLoading && viewState) {
    return (
      <Box
        sx={{
          marginTop: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  let description;
  if (serverDetails?.name) {
    console.log(serverDetails.name);
    document.title =
      serverDetails.name.charAt(0).toUpperCase() +
      serverDetails.name.slice(1) +
      " Discord Server";
    description = serverDetails?.serverPageDescription.substring(0, 160);
  }

  return (
    <div className="view-server-container">
      <Helmet>
        <meta name="description" content={description} />
      </Helmet>
      <Header />

      <div className="main-pane">
        <div className="view-server-header">
          <h1>{serverDetails?.name}</h1>
        </div>

        <ViewServerBody serverDetails={serverDetails} />
      </div>

      <Footer />
    </div>
  );
}

export default ViewServer;
