import axios from "axios";

const baseUrl_Production = "https://revote.gg";
const baseUrl_Develop = "https://dev.revote.gg";

const baseURL =
  window.location.hostname.includes("dev") ||
  window.location.hostname.includes("localhost")
    ? baseUrl_Develop
    : baseUrl_Production;

const instance = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    // Authorization:
    //   "Bearer " +
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwZmZhZWI5MS1iODVlLTQ5ODgtODIzMy01OTMyN2FiYmY2OGQiLCJleHAiOjE2NTk1NjU0MDJ9.EZKRBOjuNQ_8FlDP09RO5DYvpzvO-9mKiY4fcUxY9PPObUghgPUUR2VSsn5w0NmdbK9bmNfzUdXlto6OvllJRg",
  },
});

export default instance;
