import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import serverService from "./serverService";
import { toast } from "react-toastify";

const initialState = {
  servers: [],
  currentPage: 0,
  pageSize: 15,
  totalItems: 0,
  totalPages: 0,
  searchTag: "Community",
  sortFilter: "",
  serverDetails: [],
  playerHistory: [],
  topVoters: [],
  eligibleToVote: false,
  viewState: false,
  voteStatus: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  seo: "",
  message: "",
};

// GET Servers
export const getServers = createAsyncThunk(
  "servers/getAll",
  async (payload, thunkAPI) => {
    try {
      const currentPage = thunkAPI.getState().servers.currentPage;
      const pageSize = thunkAPI.getState().servers.pageSize;
      const sortFilter = thunkAPI.getState().servers.sortFilter;
      if (payload && payload === "tagPage") {
        const searchTag = thunkAPI.getState().servers.searchTag;
        return await serverService
          .getServers({
            currentPage,
            pageSize,
            searchTag,
          })
          .then((res) => res.data);
      } else {
        return await serverService
          .getServers({
            currentPage,
            pageSize,
            sortFilter,
          })
          .then((res) => res.data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getServersSeo = createAsyncThunk(
  "servers/seo",
  async (payload, thunkAPI) => {
    try {
      const searchTag = thunkAPI.getState().servers.searchTag;
      return await serverService.getSeo(searchTag);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const viewServer = createAsyncThunk(
  "myServers/viewServer",
  async (serverUrl, thunkAPI) => {
    try {
      return await serverService.viewServer(serverUrl);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPlayerHistory = createAsyncThunk(
  "myServers/getPlayerHistory",
  async (serverKey, thunkAPI) => {
    try {
      return await serverService.getPlayerHistory(serverKey);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTopVoters = createAsyncThunk(
  "myServers/getTopVoters",
  async (serverKey, thunkAPI) => {
    try {
      return await serverService.getTopVoters(serverKey);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getVoterEligibility = createAsyncThunk(
  "myServers/getVoterEligibility",
  async (_, thunkAPI) => {
    try {
      return await serverService.getVoterEligibility();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setUserVote = createAsyncThunk(
  "myServers/setUserVote",
  async (serverKey, thunkAPI) => {
    try {
      const response = await serverService.setUserVote(serverKey);
      thunkAPI.dispatch(getVoterEligibility());
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const serverSlice = createSlice({
  name: "server",
  initialState,
  reducers: {
    // set current page on page change - pagination
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setSearchTag(state, action) {
      function formatString(str) {
        return str
          .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
          .replace(/^[^ ]/g, (match) => match.toUpperCase());
      }

      state.searchTag = formatString(action.payload);
      state.currentPage = 0;
    },
    setRandomSortFilter(state) {
      const sortFilters = ["MOST_ACTIVE", "BUMP"];
      var randomIndex = Math.floor(Math.random() * sortFilters.length);
      state.sortFilter = sortFilters[randomIndex]; // change back to randomIndex when go live
      state.currentPage = 0;
    },
    setSortFilter(state, action) {
      state.sortFilter = action.payload;
      state.currentPage = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentPage = action.payload.currentPage;
        state.totalItems = action.payload.totalItems;
        state.totalPages = action.payload.totalPages;
        state.servers = action.payload.servers;
      })
      .addCase(getServers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(viewServer.pending, (state) => {
        state.isLoading = true;
        state.viewState = true;
      })
      .addCase(viewServer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.viewState = false;
        state.isSuccess = true;
        state.isError = false;
        state.serverDetails = action.payload;
      })
      .addCase(viewServer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.viewState = true;
        state.message = action.payload;
        toast.error(`Cannot load this server. Please try again later.`, {
          position: "bottom-left",
        });
      })

      .addCase(getPlayerHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPlayerHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.playerHistory = action.payload;
      })
      .addCase(getPlayerHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(`Cannot load player history. Please try again later.`, {
          position: "bottom-left",
        });
      })

      .addCase(getTopVoters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTopVoters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.topVoters = action.payload;
      })
      .addCase(getTopVoters.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(`Cannot load top voters. Please try again later.`, {
          position: "bottom-left",
        });
      })

      .addCase(getVoterEligibility.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVoterEligibility.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.eligibleToVote = action.payload;
      })
      .addCase(getVoterEligibility.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(`Cannot get voter eligibility. Please try again later.`, {
          position: "bottom-left",
        });
      })

      .addCase(setUserVote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setUserVote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.voteStatus = true;
        state.isError = false;
        toast.success(`You have successfully voted!`, {
          position: "bottom-left",
        });
      })
      .addCase(setUserVote.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.voteStatus = true;
        state.message = action.payload;
        toast.error(`Error submitting vote, please contact support.`, {
          position: "bottom-left",
        });
      })

      .addCase(getServersSeo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServersSeo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        console.log(action);
        state.seo = action.payload.content;
      })
      .addCase(getServersSeo.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  setCurrentPage,
  setSearchTag,
  setRandomSortFilter,
  setSortFilter,
} = serverSlice.actions;
export default serverSlice.reducer;
