import React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import "./TagsHero.css";

const TagsHero = ({ totalItems, searchTag }) => {
  return (
    <div className="tags-hero-container">
      <div className="tags-hero-left">
        <div className="bread-crumbs">
          <span className="crumbs">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="white" href="/">
                Home
              </Link>
              <Link underline="hover" color="white" href="/tags/community">
                Tags
              </Link>
              <Typography color="white">{searchTag}</Typography>
            </Breadcrumbs>
          </span>
          <span className="results">{totalItems} Results</span>
        </div>

        <h1 className="tags-hero-title">{searchTag} Discord Server</h1>
        <p className="tags-hero-text">
          Find Discord Servers and make new friends!
        </p>
      </div>

      <div className="tags-hero-right">
        <span className="results">{totalItems} Results</span>
      </div>
    </div>
  );
};

export default TagsHero;
