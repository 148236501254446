import React from "react";
import SearchBar from "../search/SearchBar";
import Filter from "./Filter";

import "./TagsFilterSection.css";

const TagsFilterSection = () => {
  return (
    <div className="tags-filter-section">
      <div className="tags-search-section">
        <SearchBar tagsPage="true" />
      </div>

      <div className="tags-filter-container">
        <Filter />
      </div>
    </div>
  );
};

export default TagsFilterSection;
