import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import "chartjs-adapter-moment";

const plugin = {
  id: "corsair",
  afterDraw: (chart) => {
    if (chart.tooltip?._active?.length) {
      var activePoint = chart.tooltip._active[0],
        x = activePoint.element.x;
      const {
        ctx,
        chartArea: { top, bottom },
      } = chart;

      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, bottom);
      ctx.lineTo(x, top);
      ctx.lineWidth = 2;
      ctx.strokeStyle = "orange";
      ctx.stroke();
      ctx.restore();
    }
  },
};

ChartJS.register(plugin);

const options = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: "index",
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  plugins: {
    hover: {
      mode: "index",
      intersect: false,
    },
    title: {
      display: true,
      text: "Player History",
    },
    corsair: {
      horizontal: false,
      vertical: true,
      color: "orange",
      dash: [],
      width: 1,
    },
    legend: {
      align: "end",
      labels: {
        usePointStyle: true,
        boxWidth: 6,
      },
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "day",
        displayFormats: {
          day: "dddd",
        },
      },
    },
  },
};

const PlayerHistoryChart = ({ data }) => {
  return <Line options={options} data={data} />;
};

export default PlayerHistoryChart;
